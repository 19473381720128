import types from './constants';

export const getOurProducts = params => ({
  type: types.GET_OUR_PRODUCTS,
  params
});

export const setOurProducts = payload => ({
  type: types.SET_OUR_PRODUCTS,
  payload
});

export const setOurProductsParams = params => ({
  type: types.OUR_PRODUCTS_SET_PARAMS,
  params
});

export const deleteOurProductsParams = () => ({
  type: types.OUR_PRODUCTS_DELETE_PARAMS
});

export const getGroupedProducts = params => ({
  type: types.GET_GROUPED_PRODUCTS,
  params
});

export const setGroupedProducts = payload => ({
  type: types.SET_GROUPED_PRODUCTS,
  payload
});

export const setGroupedProductsParams = params => ({
  type: types.SET_PARAMS_GROUPED_PRODUCTS,
  params
});

export const deleteGroupedProductsParams = () => ({
  type: types.DELETE_PARAMS_GROUPED_PRODUCTS
});

export const setGroupedProductsLoader = payload => ({
  type: types.SET_GROUPED_PRODUCTS_LOADER,
  payload
});

export const getPricelistProduct = product_id => ({
  type: types.GET_PRICELIST_PRODUCT,
  product_id
});

export const setPricelistProduct = payload => ({
  type: types.SET_PRICELIST_PRODUCT,
  payload
});

export const connectProduct = (payload, resetAll) => ({
  type: types.CONNECT_PRODUCT,
  payload,
  resetAll
});

export const connectProductSuccess = () => ({
  type: types.CONNECT_PRODUCT_SUCCESS
});

export const disconnectProduct = (id, close) => ({
  type: types.DISCONNECT_PRODUCT,
  id,
  close
});

export const disconnectProductSuccess = () => ({
  type: types.DISCONNECT_PRODUCT_SUCCESS
});

// Create Product
export const createProduct = (
  payload,
  photos,
  main_photo,
  setErrors,
  closeModal,
  setSelectedOurProducts
) => ({
  type: types.CREATE_PRODUCT_REQUEST,
  payload,
  photos,
  main_photo,
  meta: { setErrors },
  closeModal,
  setSelectedOurProducts
});

export const sendPhotosSuccess = (id, payload) => ({
  type: types.SEND_PHOTOS_REQUEST,
  id,
  payload
});

export const createMainPhoto = (id, photo) => ({
  type: types.CREATE_MAIN_PHOTO,
  id,
  photo
});

export const createBrand = (
  payload,
  toggleSwitcher,
  clearFormData,
  setDropdownValue
) => ({
  type: types.CREATE_BRAND,
  payload,
  toggleSwitcher,
  clearFormData,
  setDropdownValue
});

export const createBrandSuccess = () => ({
  type: types.CREATE_BRAND_SUCCESS
});

export const createBrandError = error => ({
  type: types.CREATE_BRAND_ERROR,
  error
});

export const getSupplier = name => ({
  type: types.GET_SUPPLIER,
  name
});

export const setSupplier = payload => ({
  type: types.SET_SUPPLIER,
  payload
});
