import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSuppliers = state => state._suppliers || initialState;

const makeSelectSuppliers = () =>
  createSelector(selectSuppliers, substate => substate.suppliers);

const makeSelectSuppliersLoading = () =>
  createSelector(selectSuppliers, substate => substate.loading_update);

const makeSelectSuppliersError = () =>
  createSelector(selectSuppliers, substate => substate.success_error);

const makeSelectSuppliersSuccess = () =>
  createSelector(selectSuppliers, substate => substate.success_update);

const makeSelectSuppliersTurnOffSuccess = () =>
  createSelector(selectSuppliers, substate => substate.turnoff_success);

export {
  makeSelectSuppliers,
  makeSelectSuppliersLoading,
  makeSelectSuppliersError,
  makeSelectSuppliersSuccess,
  makeSelectSuppliersTurnOffSuccess
};
