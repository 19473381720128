import { takeLatest, call, put } from 'redux-saga/effects';
import { getSuppliers, setSuppliers, updateSupplierSuccess } from './actions';
import types from './constants';
// import responseMock from './mockData.json'
import request from 'utils/request';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { push } from 'connected-react-router';
import routes from 'routes';
import { getSupplier } from 'cms/LinkingProducts/redux/actions';
const BASE_URL = 'admin/suppliers';

export function* fetchSuppliers({ payload }) {
  try {
    let obj = {};
    if (payload?.pagination?.pagination)
      obj.paginate = payload.pagination.pagination;
    if (payload?.pagination?.page) obj.page = payload.pagination.page;
    if (payload?.search) {
      if (payload?.search?.search) obj.search = payload.search.search;
      else obj.search = payload.search;
    }
    const searchParams = new URLSearchParams(obj);
    const response = yield call(request, {
      url: `${BASE_URL}?${searchParams}`,
      method: 'get'
    });
    yield put(setSuppliers(response));
    // yield put(setSuppliers(responseMock));
  } catch (error) {}
}

export function* updateSupplier({ payload }) {
  const response = yield call(request, {
    url: `admin/update-supplier-products/${payload}`,
    method: 'post',
    data: payload
  });

  if (response) {
    yield put(updateSupplierSuccess(response));
    yield put(
      enqueueSnackbar({
        message: response?.data?.message || 'AŽURIRAN',
        options: { variant: 'success' }
      })
    );
  } else {
    yield put(
      enqueueSnackbar({
        message: response?.data?.message || 'GREŠKA',
        options: { variant: 'error' }
      })
    );
  }

  yield put(getSuppliers());
}

export function* turnOffSupplier({ payload }) {
  const response = yield call(request, {
    url: `admin/turn-off-supplier/${payload}`,
    method: 'post',
    data: payload
  });

  if (response) {
    yield put(updateSupplierSuccess(response));
    yield put(
      enqueueSnackbar({
        message: response?.data?.message || 'ISKLJUČEN',
        options: { variant: 'success' }
      })
    );
  } else {
    yield put(
      enqueueSnackbar({
        message: response?.data?.message || 'GREŠKA',
        options: { variant: 'error' }
      })
    );
  }
  yield put(getSuppliers());
}

export default function* _suppliersSaga() {
  yield takeLatest(types.GET_SUPPLIERS, fetchSuppliers);
  yield takeLatest(types.UPDATE_SUPPLIER_REQUEST, updateSupplier);
  yield takeLatest(types.TURNOFF_SUPPLIER_REQUEST, turnOffSupplier);
}
