import React, { useCallback, useEffect, useState } from 'react';
import TableHelper from 'cms/components/Table/TableHelper';
import TableRow from 'cms/components/Table/TableRow';
import TableCell from 'cms/components/Table/TableCell';
import messages from './messages';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import { turnOffSupplierRequest, updateSupplierRequest } from './redux/actions';
import { makeSelectSuppliersLoading } from './redux/selectors';
import { BiLoader } from 'react-icons/bi';
import IsFormVisible from 'helpers/IsFormVisible';
import { makeSelectUser } from 'containers/App/selectors';

const key = '_suppliers';

const Table = ({ data }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const history = useHistory();

  const dispatch = useDispatch();
  const handleOnClick = async (type, item) => {
    // let response = null;
    // if (type === 'connected') response = await API.connectedSuppliers(item.id);
    // else response = await API.unconnectedSuppliers(item.id);
    // if (response.data && response.data !== '\n') {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute(
    //     'download',
    //     `${item.name}-${moment(new Date()).format('DDMMYYYY')}.xlsx`
    //   );
    //   document.body.appendChild(link);
    //   link.click();
    // }
    history.push(
      `/admin-panel/naši-dobavljači/${item.id}?type=${
        type === 'connected' ? 'povezani' : 'nepovezani'
      }`
    );
  };

  const handleUpdate = useCallback(
    (...args) => dispatch(updateSupplierRequest(...args)),
    [dispatch]
  );
  const handelTurnOff = useCallback(
    (...args) => dispatch(turnOffSupplierRequest(...args)),
    [dispatch]
  );

  const loading = useSelector(makeSelectSuppliersLoading());

  const user = useSelector(makeSelectUser());
  const [headItems, setHeadItems] = useState([
    messages.name,
    messages.connected_products,
    messages.unconnected_products,
    messages.updated_at
  ]);

  useEffect(() => {
    if (user) {
      if (user.roles.includes('super-admin')) {
        setHeadItems([
          messages.name,
          messages.connected_products,
          messages.unconnected_products,
          messages.actions,
          messages.updated_at
        ]);
      } else {
        if (user.permissions.includes('update-supplier')) {
          setHeadItems([
            messages.name,
            messages.connected_products,
            messages.unconnected_products,
            messages.actions,
            messages.updated_at
          ]);
        } else {
          setHeadItems([
            messages.name,
            messages.connected_products,
            messages.unconnected_products,
            messages.updated_at
          ]);
        }
      }
    }
  }, [user]);

  return (
    <TableHelper tableName="ourSuppliers" tableHeaders={headItems}>
      {data.map(item => (
        <div style={item?.turned_off ? { backgroundColor: '#ffbebe' } : {}}>
          <TableRow key={item.id}>
            <TableCell>{item.name}</TableCell>
            <TableCell>
              <Button onClick={() => handleOnClick('connected', item)}>
                {item.connected_products}
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick={() => handleOnClick('unconnected', item)}>
                {item.unconnected_products}
              </Button>
            </TableCell>
            <IsFormVisible user={user} role="update-supplier">
              <TableCell>
                <div style={{ display: 'flex', gap: '5px' }}>
                  {item?.api ? (
                    <div
                      onClick={() => {
                        if (loading) return;
                        handleUpdate(item?.id);
                      }}
                    >
                      {loading === item?.id ? (
                        <BiLoader
                          width={50}
                          height={50}
                          style={{
                            animation: 'spin 1s linear infinite',
                            width: 30,
                            height: 30,
                            marginLeft: '10px'
                          }}
                        />
                      ) : (
                        <Button variant="contained" color="primary">
                          Ažuriraj
                        </Button>
                      )}
                    </div>
                  ) : null}
                </div>
              </TableCell>
            </IsFormVisible>
            <TableCell>
              {moment(item.updated_at).format('DD.MM.YYYY HH:mm')}
            </TableCell>
          </TableRow>
        </div>
      ))}
    </TableHelper>
  );
};

export default Table;
