const IsFormVisible = ({ user, role, children }) => {
  if (user) {
    if (user.roles.includes('super-admin')) {
      return children;
    } else {
      if (user.permissions.includes(role)) {
        return children;
      } else {
        return null;
      }
    }
  }
};

export default IsFormVisible;
