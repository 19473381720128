import types from './constants';

export const getSuppliers = payload => {
  return {
    type: types.GET_SUPPLIERS,
    payload
  };
};

export const setSuppliers = payload => {
  return {
    type: types.SET_SUPPLIERS,
    payload
  };
};

export const updateSupplierRequest = payload => {
  return {
    type: types.UPDATE_SUPPLIER_REQUEST,
    payload
  };
};
export const updateSupplierSuccess = payload => {
  return {
    type: types.UPDATE_SUPPLIER_SUCCESS,
    payload
  };
};
export const updateSupplierFailure = payload => {
  return {
    type: types.UPDATE_SUPPLIER_FAILURE,
    payload
  };
};
export const turnOffSupplierRequest = payload => {
  return {
    type: types.TURNOFF_SUPPLIER_REQUEST,
    payload
  };
};
export const turnOffSupplierSuccess = payload => {
  return {
    type: types.TURNOFF_SUPPLIER_SUCCESS,
    payload
  };
};
export const turnOffSupplierFailure = payload => {
  return {
    type: types.TURNOFF_SUPPLIER_FAILURE,
    payload
  };
};
