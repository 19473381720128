import React from 'react';
import {
  ImNewspaper,
  BsViewList,
  HiOutlineMail,
  AiOutlineStar,
  BsQuestionSquare,
  RiPriceTag3Line,
  GiReceiveMoney,
  GiPayMoney,
  AiOutlinePercentage,
  VscCircleOutline,
  AiOutlineUnlock,
  FiUsers,
  FiActivity,
  GoPackage,
  AiOutlineEye,
  BiExport,
  BsListUl,
  HiOutlineShoppingCart,
  RiStackLine,
  FiUpload,
  ImTicket,
  BiImport,
  ImGift,
  FaTruckMoving,
  FaEtsy,
  HiOutlineViewGrid,
  FaGripLines
} from 'react-icons/all';
import routes from 'routes';
import messages from './messages';

export const frontend = [
  // {
  //   url: routes.CMS_ACTIONS,
  //   name: messages.actions,
  //   icon: <ImGift />,
  //   module: 'Frontend',
  //   role: 'view_category-discount-display'
  // },
  {
    url: routes.CMS_BANNERS,
    name: messages.banners,
    icon: <BsViewList />,
    module: 'Banner'
  },
  {
    url: routes.CMS_NEWS,
    name: messages.news,
    icon: <ImNewspaper />,
    module: 'News'
  },
  {
    url: routes.CMS_NEWSLETTER,
    name: messages.newsletter,
    icon: <HiOutlineMail />,
    module: 'Frontend',
    role: 'view_newsletter-product'
  },
  {
    url: routes.CMS_RATINGS,
    name: messages.ratings,
    icon: <AiOutlineStar />,
    module: 'Frontend',
    role: 'view-rating'
  },
  {
    url: routes.CMS_QUESTIONS_AND_ANSWERS,
    name: messages.questions_and_answers,
    icon: <BsQuestionSquare />,
    module: 'Frontend',
    role: 'view-question'
  }
];

export const home = [
  {
    url: routes.CMS_HOME_SCREEN_PRODUCTS,
    name: messages.products,
    icon: <VscCircleOutline />,
    module: 'Home',
    role: 'update-home-page'
  },
  {
    url: routes.CMS_HOME_SCREEN_CATEGORIES,
    name: messages.categories,
    icon: <VscCircleOutline />,
    module: 'Home',
    role: 'update-home-page'
  },
  {
    url: routes.CMS_HOME_SCREEN_SECTIONS,
    name: messages.sections,
    icon: <VscCircleOutline />,
    module: 'Home',
    role: 'update-home-page'
  }
];

export const prices = [
  {
    url: routes.CMS_RECOMMENDED_PRICE,
    name: messages.recommended_prices,
    icon: <RiPriceTag3Line />,
    role: 'view-recommended-price'
  },
  {
    url: routes.CMS_WEB_MARGINS,
    name: messages.web_marge,
    icon: <GiReceiveMoney />,
    module: 'WebMargin',
    role: 'view-web-margin'
  },
  {
    url: routes.CMS_AUTH_DISCOUNTS,
    name: messages.auth_discounts,
    icon: <GiPayMoney />,
    module: 'B2C',
    role: 'view-authenticated-discount'
  },
  {
    url: routes.CMS_CATEGORY_WEB_MARGINS,
    name: messages.web_marge_by_category,
    icon: <FaGripLines />,
    role: 'view-web-margin'
  },
  {
    url: routes.CMS_DISCOUNTS_TYPES,
    name: messages.discounts_types,
    icon: <AiOutlinePercentage />,
    role: 'view-discount-type'
  }
];

export const linking = [
  {
    url: routes.CMS_LINKING_PRODUCTS,
    name: messages.connected_products,
    icon: <VscCircleOutline />,
    role: 'connect-product'
  },
  {
    url: routes.CMS_LINKING_CATEGORIES,
    name: messages.connected_categories,
    icon: <VscCircleOutline />,
    role: 'connect-category'
  }
];

export const invoices = [
  // {
  //   url: routes.CMS_INVOICES,
  //   name: messages.invoices,
  //   icon: <VscCircleOutline />,
  //   role: 'view-proforma-invoice'
  // },
  // {
  //   url: routes.CMS_INVOICES_DATA,
  //   name: messages.data,
  //   icon: <VscCircleOutline />,
  //   role: 'view-proforma-invoice-info'
  // },
  // {
  //   url: routes.CMS_INVOICES_BANKS,
  //   name: messages.banks,
  //   icon: <VscCircleOutline />,
  //   role: 'view-proforma-invoice-info'
  // },
  // {
  //   url: routes.CMS_INVOICES_EMAILS,
  //   name: messages.emails,
  //   icon: <VscCircleOutline />,
  //   role: 'view-proforma-invoice-info'
  // }
];

export const ungrouped = [
  {
    url: routes.CMS_AUTHORIZATION,
    name: messages.authorization,
    icon: <AiOutlineUnlock />,
    module: 'Authorization',
    role: 'view-role'
  },
  {
    url: routes.CMS_USERS,
    name: messages.users,
    icon: <FiUsers />,
    role: 'view-user'
  },
  // {
  //   url: routes.CMS_ACTIVITY_LOG,
  //   name: messages.activity_log,
  //   icon: <FiActivity />,
  //   role: 'view-activity'
  // },
  {
    url: routes.CMS_PRODUCTS,
    name: messages.products,
    icon: <GoPackage />,
    role: 'view-product'
  },
  {
    url: routes.CMS_PRODUCTS_VISIBLE_ON_SITE,
    name: messages.visible_on_site,
    icon: <AiOutlineEye />,
    role: 'view-product'
  },
  // {
  //   url: routes.CMS_E_OFFER,
  //   name: messages.eOffer,
  //   icon: <FaEtsy />
  // },
  // {
  //   url: routes.CMS_E_OFFER_IMPORT,
  //   name: messages.eOfferImport,
  //   icon: <FaEtsy />
  // },
  // {
  //   url: routes.CMS_PRODUCTS_EXPORT,
  //   name: messages.products_export,
  //   icon: <BiExport />
  // },
  // {
  //   url: routes.CMS_PRODUCTS_IMPORT_CATEGORY_TO_CATEGORY,
  //   name: messages.products_import,
  //   icon: <BiImport />,
  //   module: 'ProductImport',
  //   role: 'map-products-import'
  // },
  {
    url: routes.CMS_CATEGORIES,
    name: messages.categories,
    icon: <BsListUl />,
    role: 'view-category'
  },
  {
    url: routes.CMS_ORDERS,
    name: messages.orders,
    icon: <HiOutlineShoppingCart />,
    role: 'view-order'
  },
  {
    url: routes.CMS_ATTRIBUTES,
    name: messages.attributes,
    icon: <RiStackLine />,
    role: 'view-attribute'
  },
  {
    url: routes.CMS_BRANDS,
    name: messages.brands,
    icon: <ImTicket />,
    role: 'view-brand'
  },
  {
    url: routes.CMS_UPLOAD_PRICELIST,
    name: messages.upload_price_list,
    icon: <FiUpload />,
    module: 'Pricelist',
    role: 'upload-pricelist'
  },
  {
    url: routes.CMS_OUR_SUPPLIERS,
    name: messages.our_suppliers,
    icon: <FaTruckMoving />
    // role: 'our-suppliers'
  },
  {
    url: routes.CMS_LANDING_PAGES,
    name: messages.landing_pages,
    icon: <HiOutlineViewGrid />
  }
];
