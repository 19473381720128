const OUR_SUPLLIERS = '[CMS_OUR_SUPPLIERS]';

const types = {
  GET_SUPPLIERS: `${OUR_SUPLLIERS} GET_SUPPLIERS`,
  SET_SUPPLIERS: `${OUR_SUPLLIERS} SET_SUPPLIERS`,
  UPDATE_SUPPLIER_REQUEST: `${OUR_SUPLLIERS} UPDATE_SUPPLIER_REQUEST`,
  UPDATE_SUPPLIER_SUCCESS: `${OUR_SUPLLIERS} UPDATE_SUPPLIER_SUCCESS`,
  UPDATE_SUPPLIER_FAILURE: `${OUR_SUPLLIERS} UPDATE_SUPPLIER_FAILURE`,
  TURNOFF_SUPPLIER_REQUEST: `${OUR_SUPLLIERS} TURNOFF_SUPPLIER_SUCCESS`,
  TURNOFF_SUPPLIER_SUCCESS: `${OUR_SUPLLIERS} TURNOFF_SUPPLIER_SUCCESS`,
  TURNOFF_SUPPLIER_FAILURE: `${OUR_SUPLLIERS} TURNOFF_SUPPLIER_FAILURE`
};

export default types;
