import produce from 'immer';
import types from './constants';

export const initialState = {
  suppliers: {
    data: null,
    meta: null
  },
  loading_update: false,
  success_update: null,
  error_update: null,
  turnoff_success: null,
  turnoff_error: null
};

/* eslint-disable default-case */
const _suppliers = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_SUPPLIERS:
        draft.suppliers = action.payload;
        break;
      case types.UPDATE_SUPPLIER_REQUEST:
        draft.loading_update = action.payload;
        draft.success_update = null;
        draft.error_update = null;
        break;
      case types.UPDATE_SUPPLIER_SUCCESS:
        draft.loading_update = false;
        draft.success_update = action.payload;
        break;
      case types.UPDATE_SUPPLIER_FAILURE:
        draft.loading_update = false;
        draft.error_update = action.payload;
        break;
      case types.TURNOFF_SUPPLIER_REQUEST:
        draft.loading_update = action.payload;
        draft.turnoff_success = null;
        draft.turnoff_error = null;
        break;
      case types.TURNOFF_SUPPLIER_SUCCESS:
        draft.loading_update = false;
        draft.turnoff_success = action.payload;
        break;
      case types.TURNOFF_SUPPLIER_FAILURE:
        draft.loading_update = false;
        draft.turnoff_error = action.payload;
        break;
    }
  });

export default _suppliers;
